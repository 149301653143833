import { Show, createResource, onMount } from 'solid-js'
import { useParams } from '@solidjs/router'
import ChapterUploadForm from '~/components/forms/chapter-upload/chapter-upload'
import Header from '~/components/header/header'
import Loading from '~/components/loading/loading'
import { getChapter } from '~/services/database/chapters'
import { ChapterParams } from '~/types/routes/series/chapter'
import { setLoadingState } from '~/services/loading/loading'

export default function ChapterEdit() {
  const { id } = useParams<ChapterParams>()
  const [chapter] = createResource(id, getChapter)
  
  onMount(() => {
    setLoadingState({
      loading: false
    })
  })
  
  return (
    <>
      <Header />
      <Show when={chapter()} fallback={<Loading />}>
        {(chapter) => (
          <ChapterUploadForm chapter={chapter()} />
        )}
      </Show>
    </>
  )
}
