import { InferOutput, array, instance, object, minLength, pipe } from 'valibot'
import { Database } from '~/types/supabase'

export interface ChapterUploadFormProps{
  chapter: Database['public']['Tables']['chapters']['Row']
}

export const ChapterUploadSchema = object({
  files: pipe(array(instance(File)), minLength(1))
})

export type ChapterUploadFormType = InferOutput<typeof ChapterUploadSchema>